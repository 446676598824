<template>
   <div style="width:100%;height:100%;background-color:#F2EEEE;">
      <div style="width:100%;height:100%;background-color:#F2EEEE;"> 

         <v-container 
            style="max-width:2600px;"
            class="pa-0">
            <!-- Danh sách cửa -->
            <v-row class="ma-0 pa-0 px-2">
               <v-col cols="12" class="px-2 pt-4 pb-0">
                  <div style="font-size:18px;font-weight:700;color:#444444;">
                     Danh sách cửa:
                  </div>
               </v-col>
               <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2"
                  class="pa-2 py-0 mb-2"
                  v-for="(door, index) in doors" :key="index">
                  <v-card class="pa-0">
                     <v-card-title 
                        class="py-0 pl-3 pr-1"
                        :style="{'background-color': door.online?'#7CB342':'#CCCCCC'}"
                        style="font-size:14px;color:white;line-height:1.8;">
                        {{door.name}}

                        <v-spacer/>

                        <v-menu offset-y>
                           <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                 color="#FAFAFA"
                                 dark icon
                                 v-bind="attrs"
                                 style="height:28px;width:28px;"
                                 v-on="on"
                              >
                                 <v-icon style="font-size:18px;">
                                    mdi-cog
                                 </v-icon>
                              </v-btn>
                           </template>
                        <v-list>
                           <v-list-item link
                              @click="unlockDoor(door)">
                              <v-list-item-title>Unlock</v-list-item-title>
                           </v-list-item>
                           <v-list-item link
                              @click="playVoice(door)">
                              <v-list-item-title>Play voice</v-list-item-title>
                           </v-list-item>
                           <v-list-item link
                              @click="restartDevice(door)">
                              <v-list-item-title>Restart</v-list-item-title>
                           </v-list-item>
                        </v-list>
                     </v-menu>
                     </v-card-title>
                     <v-card-text class="py-1 px-2"
                        style="color:#888888;font-size:12px;line-height:1.6;">
                        <div>IP 
                           <div style="display:inline-block;float:right;">{{door.ip}}</div></div>
                        
                        <div>Users 
                           <div style="display:inline-block;float:right;">{{door.userCount||0}}/{{door.userCap||0}}</div></div>

                        <div>Fingers 
                           <div style="display:inline-block;float:right;">{{door.fpCnt||0}}/{{door.fpCap||0}}</div></div>
                        <!--
                        <div>Password 
                           <div style="display:inline-block;float:right;">{{door.pwdCnt||0}}</div></div>
                        -->
                        <div>Face 
                           <div style="display:inline-block;float:right;">{{door.faceCnt||0}}/{{door.faceCap||0}}</div></div>

                        <div>Records 
                           <div style="display:inline-block;float:right;">{{door.recordCnt||0}}/{{door.recordCap||0}}</div></div>

                        <div>Logs 
                           <div style="display:inline-block;float:right;">{{door.oplogCnt||0}}</div></div>

                     </v-card-text>
                  </v-card>
               </v-col>
            </v-row>

            <!-- Realtime Logs -->
            <v-row class="ma-0 pa-0">
               <v-col col="12" class="pa-4 pt-0">
                  <div style="font-size:18px;font-weight:500;line-height:1.8;color:#444444;">
                     Realtime Logs:
                  </div>
                  <div  class="pa-2" 
                     style="background-color:#DDDDDD;color:#111111;overflow-y:auto;min-height:280px;max-height:280px;border: 1px solid #888888;border-radius: 4px;font-size:14px;">
                     <div v-for="(log, index) in logs" :key="index"
                        style="margin-bottom:2px;">
                        <span class="mr-4"><b>{{log.date}}</b></span>
                        {{log.content}}
                     </div>
                  </div>
               </v-col>
            </v-row>
         </v-container>
      </div>
   </div>
</template>

<script>
export default {  
   data () {
      return {
         users: [],
         departments: [],
         logs: [],
         loading: false,
         socketAskKey: new Date().getTime(),
         doors: require('@/mkdata/doors.js').doors,
         roles: ['admin'],
      }
   },
   sockets: {
      zkbot_AttTransaction: function (payload){
         // console.log('zkbot_AttTransaction')
         // console.log(payload)
         let data = JSON.parse(payload)
         let user = this.users.find(u => u.mcvId == data.userID)
         if(user) {
            this.logs.push({
               date: this.$moment().format("HH:mm:ss"),
               content: user.fullname + " xác thực " + this.resolveVerifyMethod(data.VerifyMethod) + " cửa " + this.resolveDoorName(data.devId)
            })
         } else {
            this.logs.push({
               date: this.$moment().format("HH:mm:ss"),
               content: "User " + data.userID + " xác thực " + this.resolveVerifyMethod(data.VerifyMethod) + " cửa " + this.resolveDoorName(data.devId)
            })
         }
         this.trimLog()
      },
      zkbot_OnDoor: function (payload){
         // console.log('zkbot_OnDoor')
         // console.log(payload)
         let data = JSON.parse(payload)
          this.logs.push({
               date: this.$moment().format("HH:mm:ss"),
               content: "Cửa " + this.resolveDoorName(data.devId) + " vừa mở từ trong"
            })
         this.trimLog()
      },
      'getCapabilityInfo-REPLY': function (payload) {
         if(payload && payload.askKey == this.socketAskKey){
            this.loading = false
            console.log('getCapabilityInfo-REPLY ' + payload.devCode)
            console.log(payload)
            this.doors.forEach(door => {
               if(payload.devCode.endsWith(door.ip)){
                  let result = payload.result
                  // door.online = result.userCount > 0
                  door.online = true
                  door.adminCnt = result.adminCnt
                  door.userCount = result.userCount
                  door.userCap = result.userCap
                  door.fpCnt = result.fpCnt || 0
                  door.fpRemain = result.fpRemain || 0
                  door.fpCap = door.fpCnt + result.fpRemain
                  door.recordCnt = result.recordCnt
                  door.recordCap = result.recordCap
                  door.pwdCnt = result.pwdCnt
                  door.oplogCnt = result.oplogCnt
                  door.faceCnt = result.faceCnt
                  door.faceCap = result.faceCap
               }
            })
         }
         this.$forceUpdate()
      }
   },
   methods: {
      resolveVerifyMethod(iMethod){
         if(iMethod == 0) return "mật khẩu"
         if(iMethod == 1) return "vân tay"
         if(iMethod == 2) return "thẻ từ"
         if(iMethod == 15) return "faceid"
         
         return ""
      },
      resolveDoorName(doorIp){
         let door = this.doors.find(d => d.ip == doorIp)
         if(door) return door.name
         return ""
      },
      trimLog () {
         this.logs.splice(0, this.logs.length - 100)
      },
      readDevsCapability () {
         let self = this
         this.doors.forEach((door,index) => {
            
            setTimeout(function(){
               self.$socket.emit('sendMagicCommand', 
               {
                  command: "getCapabilityInfo",
                  askKey: self.socketAskKey,
                  devCode: "MCVZK-" + door.ip,
                  paras: {}
               })
            }, 100*index)
            
         })
      },
      fetchUsers() {
         return new Promise((resolve) => {
            let self = this
            let pathFetchDataUsers =
               self.$root.apiUser + "/users/list/" + self.$root.apiUserCustomId
            let pathFetchDataDepartments =
               self.$root.apiUser + "/depts/list/" + self.$root.apiUserCustomId

            console.log(pathFetchDataUsers)
            self.loading = true
            Promise.all([
               self.axios.get(pathFetchDataUsers),
               self.axios.get(pathFetchDataDepartments),
            ])
            .then((responseArray) => {
               // console.log("Promise ALL",responseArray );
               if (responseArray[1].data.status == "OK") {
                  self.departments = responseArray[1].data.content.items;
                  // console.log(self.departments)
               }
               if (responseArray[0].data.status == "OK") {
                  self.users = responseArray[0].data.content.items;
                  self.users.forEach(u => {
                     u.mcvId = this.convertMaNVToCompanyId(u.user_code)
                     u.id = u.mcvId
                     let dept = this.departments.find(d => d._id == u.department)
                     if(dept){
                        u.department = dept
                     }
                  })
                  console.log(self.users[0])

                  // self.users = self.users.sort(function(a, b) {
                  //    return Number(a.mcvId) - Number(b.mcvId)
                  // });
                  // console.log(self.users)
               }
            })
            .catch((err) => {
               console.log(err)
            })
            .finally(() => {
               // self.processTotalReport();
               self.loading = false;
               resolve()
            });
         })
      },
      convertMaNVToCompanyId(code){
         if(code && code.length>0){
            return Number(code.replace("NV", ""))
         }
         return -1
      },
      unlockDoor (door) {
         this.$socket.emit('sendMagicCommand', 
         {
            command: "unlock",
            askKey: self.socketAskKey,
            devCode: "MCVZK-" + door.ip,
            paras: {}
         })
      },
      playVoice (door) {
         this.$socket.emit('sendMagicCommand', 
         {
            command: "playVoice",
            askKey: self.socketAskKey,
            devCode: "MCVZK-" + door.ip,
            paras: {}
         })
      },
      restartDevice (door) {
         this.$socket.emit('sendMagicCommand', 
         {
            command: "restart",
            askKey: self.socketAskKey,
            devCode: "MCVZK-" + door.ip,
            paras: {}
         })
      }
   },
   mounted() {
      this.$nextTick(() => {
         let d = new Date()
         this.readDevsCapability()
         let self = this
         setTimeout(function(){
            self.readDevsCapability() 
         }, 3000)
         this.fetchUsers().then(() => {
            console.log()
         }).catch(err => {
            console.log(err)
         })
         .finally(() => {
            console.log('Fetch all user done: ' + (new Date().getTime()-d.getTime()) + "ms")
         })
      })
   },
}
</script>

<style>
   
</style>